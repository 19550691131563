.hint {
  background: rgba(38, 38, 38, 1);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  max-width: 330px;
  z-index: 1030;
  position: absolute;

  &_mobile {
    width: calc(100vw - 60px);
    margin: 0;
  }

  &__h1 {
    font-size: 16px;
    color: white;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__tail {
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    top: -6px;
    background: inherit;
    z-index: -1;
    left: 32px;
  }

  &_hor_right {
    margin-left: 1.6rem;
  }

  &_ver_bottom &__tail {
    bottom: -6px;
    top: auto;
  }

  &_hor_right &__tail {
    right: 32px;
    left: auto;
  }
}
