@use 'sass:math';
@import "../../all-modules.global";

.support-widget {
  $button-size: 60px;
  $item-size: 50px;
  $icon-size: 32px;
  $height: ($item-size + 8px) * 4;
  position: fixed;
  bottom: calc(var(--contnet-y-padding) + 40px);
  right: var(--contnet-y-padding);
  margin-left: 72px;
  z-index: 1000;

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    width: $button-size;
    align-items: center;
    transition: .4s;
  }

  &_open &__list {
    margin-top: -$height;
  }

  & &__hint {
    margin: 8px 0 0 -85px;
    width: 154px;
    cursor: pointer;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
  }

  &__item {
    width: $item-size;
    height: $item-size;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: $icon-size;
    cursor: pointer;
    transition: .4s;
    margin: 0 0 (-$item-size) 0;
    position: relative;

    &::after {
      font: normal normal normal 24px fontello;
      text-align: center;
      line-height: $item-size;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
    }
  }

  &__item a {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &_open &__item {
    margin: 0 0 8px 0;
  }

  &__item_type_tg {
    background-color: #0285ce;
    background-image: url("/static/img/tg.svg");
  }

  &__item_type_vk {
    background-color: #007bb6;
    &::after {
      content: "\f189";
      color: #fff;
    }
  }

  &__item_type_email {
    background-color: #00baa8;
    &:after {
      content: '';
      width: $icon-size;
      height: $icon-size;
      background-color: #fff;
      mask-image: url("/static/img/email.svg");
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
    }
  }

  &__item_type_chat {
    background-color: var(--brand);
    &:after {
      content: '';
      width: $icon-size;
      height: $icon-size;
      background-color: #fff;
      mask-image: url("/static/img/message-chat.svg");
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
    }
  }

  &__button {
    width: $button-size;
    height: $button-size;
    border: none;
    border-radius: 50%;
    @include shadow;
    @include font-size(l);
    outline: none;
    text-decoration: none;
    color: #fff;
    background-color: var(--brand);
    background-image: url("/static/img/dialog.svg");
    background-size: $icon-size;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: .3s ease-in-out;
    animation: btn-pulsate 2s ease-out;
    animation-iteration-count: infinite;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: $button-size;
      height: $button-size;
      top: 0;
      border-radius: 50%;
      left: 0;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
      border: 3px solid var(--brand);
      animation: pulsate 2s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
    }
  }

  &_open &__button {
    background-color: #fff;
    background-image: url("/static/img/cross.svg");
    animation: none;

  }

  &_open &__button::after {
    content: none;
  }
  
  
  @media (max-width: 1044px) {
    & {
      bottom: var(--contnet-y-padding);
    }
    &__hint {
      display: none;
    }
  }

  @media (max-width: $header-menu-width) {
    & {
      display: none;
    }
  }
}

@keyframes btn-pulsate {
  0% {
    transform: scale(1,1)
  }

  25% {
    transform: scale(1.1,1.1)
  }

  50% {
    transform: scale(1,1)
  }
}

@keyframes pulsate {
  0% {
    transform: scale(.8, .8);
    opacity: 0
  }

  25% {
    opacity: .4
  }

  50% {
    transform: scale(1.3,1.3);
    opacity: 0
  }

  100% {
    transform: scale(.8,.8);
    opacity: 0
  }
}
