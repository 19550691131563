@mixin font-size($size) {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
}

@mixin text-size {
  &_size_xs {
    @include font-size(xs);
  }
  &_size_s {
    @include font-size(s);
  }
  &_size_sm {
    @include font-size(sm);
  }
  &_size_m {
    @include font-size(m);
  }
  &_size_l {
    @include font-size(l);
  }
  &_size_xl {
    @include font-size(xl);
  }
  &_size_xxl {
    @include font-size(xxl);
    font-weight: 800;
  }
}
